import React, { useEffect } from 'react';
import GridTableWrapper from '@/components/table/newGridTable';
import { FormStateType, TableDataFields, TableWrapperProps } from './types';
import { defaultFormTemp, useTable } from './hooks';

export const TableWrapper = (props: TableWrapperProps) => {
  const { currentRow, setCurrentRow, handleList, setFormTemp, methods, ...field } = useTable();
  const tableProps = { currentRow, methods, ...field };

  // 加载列表数据
  useEffect(() => {
    handleList();

    return () => setFormTemp(defaultFormTemp);
  }, []);

  // 选中row后进行操作
  useEffect(() => {
    if (currentRow) {
      props.onSelect(currentRow);
      setCurrentRow?.(null);
    }
  }, [currentRow]);

  return (
    <GridTableWrapper<TableDataFields, FormStateType> { ...tableProps } />
  );
};
