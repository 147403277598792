import React from 'react';
import { Box, styled } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridSelectionModel, GridValidRowModel } from '@mui/x-data-grid';
import { DefaultTableResolve, TableGridColumns } from '@/utils/types';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return ({
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f8f8f9',
      overflow: 'initial',
    },
  });
});

function GridTableWrapper<T, S>(props: DefaultTableResolve<T, S>) {
  const { columns, formTemp, methods, setSelectRows, enableSelect, isRowSelectable, isPage = true } = props;
  const loading = (formTemp && formTemp.loading) || false;
  const rows = formTemp.data || [];

  // rowKey設置
  const key = (columns as TableGridColumns).find((item: GridValidRowModel) => item.key === true)?.field || 'id';
  const getRowId = (row: GridValidRowModel) => row[key];

  // 選中
  const handleOnSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    const data: T[] = rows;
    const row = selectionModel.map(item => data.find((el: any) => el[key] === item));
    setSelectRows?.(row as T[]);
  };

  const currentPage = formTemp.currentPage ? formTemp.currentPage - 1 : 0;
  const total       = formTemp.total || 0;
  const perPage     = formTemp.perPage || 0;

  return (
    <Box
      sx={{
        flex: '1 0 0',
        width: '100%',
        height: '100%',
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
      }}>
      <StyledDataGrid
        // TODO 后续删除.slice(currentPage * perPage, currentPage * perPage + perPage)
        rows={rows.length <= perPage ? rows : rows.slice(currentPage * perPage, currentPage * perPage + perPage)}
        columns={columns.map((item) => ({ align: 'center', headerAlign: 'center', sortable: false, ...item }))}
        pagination
        page={currentPage}
        rowCount={total}
        pageSize={perPage}
        rowsPerPageOptions={[10, 25, 100]}
        paginationMode="server"
        onPageChange={methods.onPageChange}
        onPageSizeChange={methods.onPageSizeChange}
        disableColumnFilter
        disableColumnMenu
        checkboxSelection={setSelectRows !== undefined && enableSelect !== false}
        disableSelectionOnClick
        loading={loading}
        getRowId={getRowId}
        onSelectionModelChange={handleOnSelectionModelChange}
        columnBuffer={columns.length}
        hideFooter={!isPage}
        isRowSelectable={isRowSelectable}
      />
    </Box>
  );
};

GridTableWrapper.displayName = 'GridTableWrapper';

export default GridTableWrapper;
