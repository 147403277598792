import React, { useState, useEffect, useRef, FC } from 'react';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Logout from '@mui/icons-material/Logout';
import { Avatar, Badge, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { observer }      from 'mobx-react-lite';
import { Link }          from 'react-router-dom';
import { Header }        from '@/components/layout';
import { userInfoStore } from '@/models';
import Message           from '@/components/message/message';
import { sysName, topContennt, company } from './styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ChangePasswordModal, { IChangePasswordModalRef } from './changePasswordModal';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { useChat } from '@/components/im/hooks';
import { postLog } from '@/services/api/log';
import { CarouselNotice } from './carouselNotice';
import { GetNoticeUnRead } from '@/services/api/notice';
import { useRequest } from 'ahooks';
import PubSub from 'pubsub-js';
import { UPDATE_UNREAD_COUNT_EVENT } from '@/constants';

const UserMenu = observer(() => {
  const { userName, logout } = userInfoStore;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const changePasswordModalRef = useRef<IChangePasswordModalRef>(null);

  const { chatListData, setOpenChatList } = useChat();

  const imUnreadArray = chatListData.map(item => item.unreadCount) || [];
  const imUnreadCount = imUnreadArray.length > 0 ? imUnreadArray.reduce((total, num) => total + num) : 0;

  const getNoticeUnReadRequest = useRequest(GetNoticeUnRead, {
    manual: true,
    pollingInterval: 1000 * 60 * 3, // 3分钟一次
    pollingErrorRetryCount: 3,
    onSuccess: (res) => {
      const { Data } = res;
      userInfoStore.setUnreadCount(Data || 0);
    },
    onError(error) {
      Message.error((error as Error));
    },
  });
  useEffect(() => {
    getNoticeUnReadRequest.run();
    const updateUnreadCountEventToken =  PubSub.subscribe(UPDATE_UNREAD_COUNT_EVENT, () => {
      getNoticeUnReadRequest.run();
    });
    return () => {
      PubSub.unsubscribe(updateUnreadCountEventToken);
    };
  }, []);

  const handleLogout = () => {
    postLog({ Comment: '退出登錄' }).finally(() => {
      logout();
      location.replace('/');
    });
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleResetPassword = () => {
    changePasswordModalRef.current?.open();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenChatList = () => {
    setOpenChatList(true);
  };

  const open = Boolean(anchorEl);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div onClick={handleOpenChatList}>
        <Badge badgeContent={imUnreadCount} max={99} color="secondary" sx={{ marginRight: 2, cursor: 'pointer' }}>
          <TextsmsOutlinedIcon sx={{ color: '#bdbdbd', fontSize: 25 }} />
        </Badge>
      </div>
      <Link to="/notice">
        <Badge badgeContent={userInfoStore.unreadCount} color="secondary">
          <NotificationsOutlinedIcon sx={{ color: '#bdbdbd', fontSize: 25 }} />
        </Badge>
      </Link>
      <IconButton onClick={handleClick} size='small' sx={{ ml: 2 }}>
        <Avatar sx={{ width: 32, height: 32 }}>{userName[0]}</Avatar>
        <Typography variant='body2' sx={company}>{userName}</Typography>
      </IconButton>
      <ChangePasswordModal ref={changePasswordModalRef}/>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleResetPassword}>
          <ListItemIcon>
            <LockOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>修改密碼</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          <ListItemText>退出賬號</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
});

const LayoutHeader:FC = () => {
  return (
    <Header>
      <Box sx={topContennt}>
        <Stack direction={'row'}>
          <Typography variant='h5' sx={sysName}>
            In Time
          </Typography>
          <CarouselNotice/>
        </Stack>
        <UserMenu />
      </Box>
    </Header>
  );
};

export default LayoutHeader;
