import _ from 'lodash';
import React, { memo, UIEventHandler, useCallback, useEffect } from 'react';
import dayjs                            from 'dayjs';
import LazyImg                          from '../lazyImg';
import MapLayer                         from '../map';
import { useChat }                      from '../../hooks';
import { ChatItemField, ContentFields } from '../../types';
import { usePreView }                   from '../preview/hooks';
import filedownIcon                     from '../../icon/filedown.png';
import { useMapView }                   from '../map/hooks';
import styles                           from './index.module.css';

let oldCurrent: ChatItemField | null = null;
const IMsgItemResolve = ({ username, avatar }: { username: string | undefined, avatar: string | undefined }) => {
  if (!username) return null;
  return avatar ? <img className={styles.avatarImageCss} src={avatar} alt={username} /> : <span>{ username.slice(0, 1).toUpperCase() }</span>;
};

const dateTimeResolve = (timestamp: number) => {
  if (dayjs(new Date()).format('MM-DD') === dayjs(timestamp).format('MM-DD')) {
    return `今天 ${dayjs(timestamp).format('HH:mm:ss')}`;
  }
  return dayjs(timestamp).format('MM-DD HH:mm:ss');
};

// 消息框主体部分
const MessageWrapper = () => {
  const { current, myInfo } = useChat((store) => [store.current, store.myInfo]);
  const { setOpen, setPreViewImage } = usePreView();
  const { setOpenMap, setCoords } = useMapView();

  let isScroll: Boolean = false;
  let timer: number = 0;

  useEffect(() => {
    if ((current && current.content.length <= 10) || oldCurrent?.id !== current?.id) handleLiScrollChange();
    oldCurrent = current;
  }, [current]);

  // 滚动到最新的聊天信息位置
  const handleLiScrollChange = () => {
    setTimeout(() => {
      const li = document.querySelectorAll('.li_0');
      if (li.length > 0) li[li.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      else document.querySelector('.ul')?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 50);

    // TODO 暂时保留
    // const scrollTop = document.querySelector('.message')?.scrollTop;
    // if (!scrollTop) document.querySelector('.ul')?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  // 监听滚动条
  const handleOnScroll: UIEventHandler<HTMLDivElement> = (event) => {
    if (isScroll) return;

    isScroll = true;
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      const target = event.target as HTMLDivElement;
      // 滚到顶了
      // if ((target.offsetHeight + target.scrollTop) >= target.scrollHeight) {
      if (target.scrollTop <= 20) {
        if (current) {
          const newRow: ChatItemField = _.cloneDeep(current);
          newRow.onClick = current.onClick;
          newRow.onInput = current.onInput;
          newRow.onScroll = current.onScroll;
          newRow.onScroll && newRow.onScroll(newRow);
        }
      }
      isScroll = false;
    }, 500);
  };

  // 图片预览
  const handlePreviewImage = useCallback((imageUrl: string) => {
    setPreViewImage(imageUrl);
    setOpen(true);
  }, []);

  // 文件下载
  const handleFileDownLoad = useCallback((fileUrl: string) => {
    window.open(fileUrl);
  }, []);

  const handleOpenMap = (lat: number, lng: number) => {
    setCoords({ lat, lng });
    setOpenMap(true);
  };

  if (!current) return null;
  const content = current.content || [];

  // Text=0, Location=1,Image=2, Voice=3
  const messageResolve = (data: ContentFields) => {
    // 位置
    if (data.type === 1) {
      let coords = { latitude: 0, longitude: 0 };
      try {
        coords = JSON.parse(data.message);
      } catch {
        coords = { latitude: 0, longitude: 0 };
      }

      return (
        <div className={styles.map} onClick={() => handleOpenMap(coords.latitude, coords.longitude)}>
          { coords ? <MapLayer lat={coords.latitude} lng={coords.longitude} /> : <span>加載異常</span> }
        </div>
      );
    }

    // 图片
    if (data.type === 2) {
      return <LazyImg src={data.message} alt='01' onClick={() => handlePreviewImage(data.message)} />;
    }

    // 音频
    if (data.type === 3) {
      return (
        <audio style={{ maxWidth: '100%', height: '35px' }} src={data.message} controls></audio>
      );
    }

    // 文件
    if (data.type === 5) {
      return (
        <div className={styles.fileDownload}>
          <img style={{ maxWidth: '70px', cursor: 'pointer' }} src={filedownIcon} onClick={() => handleFileDownLoad(data.message)} />
          <div className={styles.filename}>{data.message.split('/').pop()}</div>
        </div>
      );
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: data.message.replace(/\n/g, '<br>') }}></div>
    );
  };

  return (
    <div className={`${styles.messageWrapper} message`} onScroll={handleOnScroll}>
      <ul className={`${styles.list} ul`}>
        {
          content.map((item, key: number) => (
            <li key={key} className={`${styles.itemCss} ${item.target === 0 ? styles.itemToCss : `li_${item.status}`}`}>
              <div className={styles.avatarCss}>
                {
                  item.target === 0
                    ? <IMsgItemResolve username={myInfo?.username} avatar={myInfo?.avatar} />
                    : <IMsgItemResolve username={item.userName} avatar={current.avatar} />
                }
              </div>
              <div className={styles.msgCss}>
                {
                  item.target !== 0 && <div className={styles.top}>
                    <span className={styles.name}>{item.userName}</span>
                    <span className={styles.time}>{dateTimeResolve(item.time * 1000)}</span>
                  </div>
                }
                <i className={styles.triangle}></i>
                <div className={styles.msgBody}>
                  { messageResolve(item) }
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default memo(MessageWrapper);
