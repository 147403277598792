import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Stack, TextField, MenuItem, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { operationBar, noBoxShadow } from '@/styles';
import { Filter, FilterData }                    from '@/utils/filter';
import { IOption }                   from '@/utils/types';
import { useTable }                  from './hooks';
import { FilterFields }              from './types';

const sexOptions: IOption[] = (FilterData.sex as IOption[]).map((item) => {
  if (item.value === 0) {
    return { value: 0, label: '全部' };
  }
  return item;
});
const driverStatusOptions: IOption[] = Filter('driverStatus');

export const FilterWrapper = () => {
  const { control, register, getValues, reset } = useForm<FilterFields>();
  const { formTemp, handleList } = useTable();

  // 搜索
  const handleSearch = () => {
    const params = getValues();
    handleList({ ...params, PageIndex: 1, PageSize: formTemp.perPage });
  };

  // 重置
  const handleReset = () => {
    reset({
      DriverInfo: '',
      DriverSex: 0,
      SsnNumber: '',
      DriverStatus: 0,
      DriverNumber: '',
    });
    handleSearch();
  };

  return (
    <Box sx={operationBar}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction='row' spacing={1}>
          <TextField label='編號' variant='outlined' size='small' placeholder='請輸入編號' {...register('DriverNumber')} />
          <TextField label='姓名' variant='outlined' size='small' placeholder='請輸入姓名' {...register('DriverInfo')} />

          <Controller
            name="DriverSex"
            control={control}
            defaultValue={0}
            render={
              ({ field }) => (
                <TextField select label='性別' size='small' sx={{ width: 200 }} {...field}>
                  {sexOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }
          />

          <TextField label='SSN' variant='outlined' size='small' placeholder='請輸入SSN' {...register('SsnNumber')} />
          <Controller
            name="DriverStatus"
            control={control}
            defaultValue={0}
            render={
              ({ field }) => (
                <TextField select label='狀態' size='small' sx={{ width: 200 }} {...field}>
                  {driverStatusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }
          />
          <Button variant='contained' sx={noBoxShadow} onClick={handleSearch}>搜索</Button>
          <Button variant='outlined' sx={noBoxShadow} onClick={handleReset}>重置</Button>
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};
