import { OptionsObject, SnackbarMessage } from 'notistack';

export default class Message {
  static open = (message: SnackbarMessage, options?: OptionsObject | undefined) => {};
  static error = (error: Error | SnackbarMessage, options?: OptionsObject | undefined) => {
    if (error instanceof Error) {
      Message.open(error.message, options || { variant: 'error' });
    } else {
      Message.open(error, options || { variant: 'error' });
    }
  };
}
