import React, { FC } from 'react';
import Box from '@mui/material/Box';

interface IHeaderProps {
  children?: React.ReactNode;
}

const Header: FC<IHeaderProps> = ({ children }) => {
  return (
    <Box
      sx={{
        width: 1,
        paddingX: 2,
        paddingY: 1,
        backgroundColor: 'primary.main',
      }}
    >
      {children}
    </Box>
  );
};
export default Header;
