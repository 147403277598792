import { ILoadLogsDto, IPostLogParams, IRequestSearchLogsParams } from '@/types/operationLog.type';
import { api } from './api';
import { IResolve } from '@/types/communal.types';

// 获取当前org下的所有操作日志
export const getOperationLogs = async (params: IRequestSearchLogsParams): Promise<IResolve<ILoadLogsDto>> => {
  return await api.get('/Log', { params });
};

// 记录登录操作
export const postLog = async (params?: IPostLogParams): Promise<IResolve<null>> => {
  return await api.post('/Log', params);
};
