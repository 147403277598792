import { api } from './api';
import { IResolve } from '@/types/table.base.types';
import { IUploadFileResponseDto, TemplateFileFields } from '@/types/files';

// 获取模板文件url
// 0/司機模板、1/車輛模板、2/訂單模板、3/地址模板、4/排单结果、5/订单导出、6/司機邀請模板
export const GetTemplateFileUrl = async (params: TemplateFileFields): Promise<IResolve<string>> => {
  return await api.get('/attachment/getTemplateFileUrl', { params });
};

// 获取七牛Token
export const GetQinNiuToken = async (): Promise<IResolve<string>> => {
  return await api.get('/attachment/getQinNiuToken');
};

// 上傳文件
export const uploadFile = async (data: FormData): Promise<IResolve<IUploadFileResponseDto>> => {
  return await api.post('/attachment/uploadFile', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
