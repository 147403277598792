import './index.scss';
import React, { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Sider }                      from '../../components/layout';
import Menu                           from '../../components/menu';
import LayoutHeader                   from './header';
import { userInfoStore }              from '../../models';
import { Breadcrumb }                 from '../../components/breadcrumb';

import { Env } from '@/config/env';
import SignalRWrapper from '@/components/signalr';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import arms from '@/utils/arms';
import storage from '@/utils/storage';
import { MENU_AUTH_LIST } from '@/constants';

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const fallbackRender = ({ error }: FallbackProps) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    const regex = /Loading chunk \d+ failed/;
    if (regex.test(error.message)) {
      window.location.reload();
      return;
    }
    arms.error(error);
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    );
  };
  const goDashboard = () => {
    const menus = storage.localGet(MENU_AUTH_LIST);
    if (location.pathname === '/' && menus?.includes('DASHBOARD')) {
      navigate('/dashboard');
    }
  };
  useEffect(() => {
    // if (!userInfoStore.userId) {
    userInfoStore.requestUserInfo();
    // }
    if (!userInfoStore.hereMapKey) {
      userInfoStore.requestHereKey();
    }
  }, []);

  useEffect(() => {
    window.changeSiderCollapsed = (data: boolean) => {
      setCollapsed(data);
    };
    window.goDashboard = goDashboard;
  }, []);

  return (
    <div className='main_layout'>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <LayoutHeader />
        <div className='body_bar'>
          <Sider collapsible={true} collapsed={collapsed} onCollapse={(status) => setCollapsed(status)}>
            <Menu />
          </Sider>
          <div className='content_bar flex flex-column'>
            <Breadcrumb />
            <div className="suspense-layer">
              <Suspense>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
        <SignalRWrapper token={userInfoStore.token} url={Env.tmsHubUrl}></SignalRWrapper>
      </ErrorBoundary>
    </div>
  );
};

export default MainLayout;
