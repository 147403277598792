import { flow, types } from 'mobx-state-tree';
import moment from 'moment-timezone';
import { MENU_AUTH_LIST, TMS_ORGANIZATION_ID_KEY, TMS_TOKEN_KEY } from '../../constants';
import { getHereMapKey, getUserInfo } from '../../services/api/user';
import { IOrganization, IUserInfo, IUserInfoResolve } from '../../services/api/user.types';
import storage from '../../utils/storage';
import arms from '@/utils/arms';

const Organization = types.model('Organization', {
  Id: types.identifier,
  Name: types.string,
  FullName: types.string,
  TimezoneId: types.string,
});
const Organizations = types.array(Organization);

export const checkTokenExp = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    if (decodedToken && decodedToken.exp) {
      return decodedToken.exp > new Date().valueOf() / 1000;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const UserInfoStoreModel = types
  .model('UserInfoStoreModel')
  .props({
    token: types.optional(types.string, ''),
    userName: types.optional(types.string, ''),
    userId: types.optional(types.string, ''),
    organizationId: types.optional(types.string, ''),
    organizations: types.optional(Organizations, []),
    hereMapKey: types.optional(types.string, ''),
    unreadCount: types.optional(types.number, 0),
    markerRemarUnreadCount: types.optional(types.number, 0),
  })
  .views((self) => ({
    get organization() {
      if (self.organizationId) {
        return self.organizations.filter((item) => item.Id === self.organizationId);
      } else {
        return null;
      }
    },
    get timezoneId() {
      if (this.organization && this.organization?.length > 0) {
        return this.organization[0].TimezoneId;
      }
      return moment.tz.guess(true);
    },
  }))
  .actions((self) => {
    const setToken = (token: string) => {
      self.token = `Bearer ${token}`;
      storage.localSet(TMS_TOKEN_KEY, token);
    };
    const setOrganizations = (organizations: IOrganization[]) => {
      self.organizations = Organizations.create(organizations);
    };
    const updateUserInfo = (user: IUserInfo) => {
      self.userName = user.UserName;
      self.userId = user.UserId;
      arms.setConfig({
        uid: user.UserId,
        setUsername: () => {
          return user.UserName || null;
        },
      });
      setOrganizations(user.Organizations);
    };
    const logout = () => {
      self.userName = '';
      self.userId = '';
      self.token = '';
      self.organizationId = '';
      self.organizations = Organizations.create([]);
      storage.localRemove(TMS_ORGANIZATION_ID_KEY);
      storage.localRemove(TMS_TOKEN_KEY);
      self.hereMapKey = '';
    };
    const requestUserInfo = flow(function* requestUserInfo() {
      const { Data }: IUserInfoResolve = yield getUserInfo();
      const menClaimModels = Data.MenClaimModels || [];
      const menuAuthList = menClaimModels.map(item => item.MenName.toUpperCase());
      storage.localSet(MENU_AUTH_LIST, menuAuthList);
      window.refreshMenuAuthList?.(menuAuthList);
      updateUserInfo(Data);
    });
    const requestHereKey = flow(function* requestUserInfo() {
      const { Data } = yield getHereMapKey();
      self.hereMapKey = Data;
    });
    const setOrganization = (organizationId: string) => {
      self.organizationId = organizationId;
      storage.localSet(TMS_ORGANIZATION_ID_KEY, organizationId);
    };
    // 钩子函数
    const afterCreate = () => {
      const token = storage.localGet(TMS_TOKEN_KEY);
      const organizationId = storage.localGet(TMS_ORGANIZATION_ID_KEY);
      if (token && typeof token === 'string' && checkTokenExp(token)) {
        setToken(token);
      } else {
        storage.localRemove(TMS_TOKEN_KEY);
      }
      if (organizationId && typeof organizationId === 'string') {
        setOrganization(organizationId);
      }
    };
    const setUnreadCount = (value: number) => {
      self.unreadCount = value;
    };

    const setMarkerRemarUnreadCount = (value: number) => {
      self.markerRemarUnreadCount = value;
    };

    return {
      setToken,
      updateUserInfo,
      logout,
      requestUserInfo,
      requestHereKey,
      afterCreate,
      setOrganization,
      setOrganizations,
      setUnreadCount,
      setMarkerRemarUnreadCount,
    };
  });
export const userInfoStore = UserInfoStoreModel.create();
