import React from 'react';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import { Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps, DialogWrapperProps } from './types';

export const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogWrapper = styled((props: DialogWrapperProps) => {
  const { muiPaperRoot, ...dialogProps } = props;
  return (
    <Dialog {...dialogProps} />
  );
})((props: DialogWrapperProps) => {
  const { muiPaperRoot } = props;
  return (
    {
      '& .MuiPaper-root': {
        minWidth: '600px',
        ...muiPaperRoot,
      },
    }
  );
});
