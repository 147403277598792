import React from 'react';
import { usePreView } from './hooks';
import styles from './index.module.css';

const PreViewWrapper = () => {
  const { open, setOpen, preViewImage } = usePreView();
  return (
    <>
      {
        !open || !preViewImage
          ? null
          : <div className={styles.previewLayer}>
              <div className={styles.maskLayer} onClick={() => setOpen(false)}></div>
              <div className={styles.imageLayer}>
                <img className={styles.image} src={preViewImage} />
              </div>
          </div>
      }
    </>
  );
};

export default PreViewWrapper;
