import _ from 'lodash';

type RuleType = {
  [key: string]: (value: string) => boolean | RegExp;
};

/**
 * 正則表達式
 */
export const Rules: RuleType = {
  // 为空
  empty(value: string) {
    return _.isEmpty(value + '') || false;
  },

  // 邮箱
  email(value: string) {
    const pattern = /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 美國電話號碼和手機號碼
  phone(value: string) {
    const pattern = /^(\([2-9][0-9]{2}\)\s?|[2-9][0-9]{2}[-.]?)[2-9][0-9]{2}[-.]?[0-9]{4}$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 密码
  password(value: string) {
    const pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/g;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 身份证
  identity(value: string) {
    const pattern = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 银行卡
  bank(value: string) {
    const pattern = /^(\d{16,19})$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 信用卡
  creditCard(value: string) {
    const pattern = /^(\d{15,19})$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 验证码
  smsCode(value: string) {
    const pattern = /^[0-9]{6}$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 替换金额
  checkMoney(value: string) {
    const pattern = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 金额
  money(value: string) {
    const pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 日期
  date(value: string) {
    const pattern =
      /^(([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29)$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 整数 和 0
  number(value: string) {
    const pattern = /^\+?[1-9][0-9]*|[0]$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 正整数
  integer(value: string) {
    const pattern = /^\+?[1-9][0-9]*$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 正则图片格式
  images(value: string) {
    const pattern = /^.*(gif|png|jpe?g)$/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },

  // 特殊字符
  special(value: string) {
    const pattern = /[`~!@#$^&*()=|{}':;',\\[\\]<>\/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]/;
    if (_.isEmpty(value)) return pattern;
    return !pattern.test(value) || false;
  },
  http(value: string) {
    const pattern = /^(http|https):\/\/([\w.]+\/?)\S*/;
    if (_.isEmpty(value)) return pattern;
    return pattern.test(value) || false;
  },
};

// const Validator = (key: string, value: string) => /^\d+$/.test(value);
// export const Validator = (key: string, value?: string) => rules[key](value);
// export const Validator = (key: string, value?: string) => rules[key as keyof RulesType][value]?.();
