import dayjs from 'dayjs';
import { converTime } from './format';

// 过滤空数据
export function FilterEmptyData<T>(params: T): T {
  for (const i in params) {
    if (params[i] as unknown === '' || params[i] as unknown === null) delete params[i];
    else if (Object.prototype.toString.call(params[i]) === '[object Date]') {
      const newDate = (params[i] as unknown) as Date;
      const dateTimeStr = converTime(newDate.toJSON());
      params[i] = (dayjs(dateTimeStr).format('YYYY-MM-DD') as unknown) as T[Extract<keyof T, string>];
    }
  }
  return params;
};

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
