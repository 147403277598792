import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { createModel } from 'hox';
import Recorderx, { ENCODE_TYPE } from 'recorderx';

import { ChatItemField, ContentFields, MyInfoField } from './types';

export const FormatMessageType = (el: ContentFields) => {
  let text = el.message;
  switch (el.type) {
    case 1:
      text = '[位置]';
      break;
    case 2:
      text = '[圖片]';
      break;
    case 3:
      text = '[語音]';
      break;
    case 4:
      text = '[訂單]';
      break;
    case 5:
      text = '[文件]';
      break;
  }
  return text;
};

const useChatResolve = () => {
  const [myInfo, setMyInfo] = useState<MyInfoField | null>(null);
  const [chatListData, setChatListData] = useState<ChatItemField[]>([]);
  const [oldData, setOldData] = useState<ChatItemField[]>([]);
  const [current, setCurrent] = useState<ChatItemField | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [msgType, setMsgType] = useState<number>(0); // Text=0, Location=1,Image=2, Voice=3
  const [openChatList, setOpenChatList] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const previewRef = useRef(null);
  const currentId: string | null = null;

  useEffect(() => {
    handleClearSearch();
  }, [searchValue]);

  const handleDataMake = (data: ChatItemField[]) => {
    setChatListData(data);
    if (data.length > 0) setCurrent(data[0]);
  };

  // 取消搜索
  const handleClearSearch = () => {
    const copyData: ChatItemField[] = _.cloneDeep(oldData);
    const newData = copyData
      .splice(copyData.findIndex(item => item.id === current?.id), 1)
      .concat(...copyData)
      .filter(item => item.username.toLowerCase().includes(searchValue.toLowerCase()));
    setChatListData(newData);
  };

  // 插入消息
  const InsertMessage = (newMessage: ContentFields) => {
    if (!current) return;

    const copyData: ChatItemField[] = _.cloneDeep(chatListData);
    const data = copyData
      .splice(copyData.findIndex(item => item.id === current.id), 1)
      .concat(...copyData);

    const index = data.findIndex(item => item.id === current.id);
    if (index !== -1) {
      data[index].content = current.content.concat(newMessage);

      if (current.onInput) {
        current.onInput(current.id as string, newMessage)
          .then((res) => {
            if (res.status === 'ok') {
              setCurrent(data[index]);
              setChatListData(data);
            }
          });
      }
    }
  };

  return {
    myInfo,
    setMyInfo,
    chatListData,
    setChatListData,
    oldData,
    setOldData,
    current,
    setCurrent,
    searchValue,
    setSearchValue,
    handleDataMake,
    handleClearSearch,
    textareaValue,
    setTextareaValue,
    isFocus,
    setIsFocus,
    msgType,
    setMsgType,
    textareaRef,
    previewRef,
    InsertMessage,
    currentId,
    openChatList,
    setOpenChatList,
  };
};

/**
 * 录音
 */
let maxRecordTime: number = 60;
let timer: ReturnType<typeof setInterval>;

const rc = new Recorderx();
const useRecordStore = () => {
  const [isRecord, setIsRecord] = useState<boolean>(false); // 录音
  const [isPause, setIsPause] = useState<boolean>(false); // 停止
  const [isSend, setIsSend] = useState<boolean>(false); // 发送

  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    return () => clearInterval(timer);
  }, []);

  // 开始录音
  const startRecord = () => {
    maxRecordTime = 60;
    rc?.clear();
    rc.start()
      .then(() => {
        setDuration(1);
        setIsRecord(true);
        setIsPause(false);
        setIsSend(false);
        handleSetTimeOut();
      })
      .catch((error: Error) => alert(error.message));
  };

  // 停止录音
  const stopRecord = () => {
    if (timer) clearInterval(timer);
    rc.pause();
    setIsPause(true);
  };

  // 取消
  const handleCancel = () => {
    setIsRecord(false);
  };

  // 发送
  const handleSend = () => {
    setIsSend(true);
    setIsRecord(false);
  };

  // 获取录音文件
  const getRecord = () => {
    const blob = rc.getRecord({ encodeTo: ENCODE_TYPE.WAV });
    return {
      file: blob,
      duration,
      url: window.URL.createObjectURL(blob),
    };
  };

  // 录音倒计时
  const handleSetTimeOut = () => {
    if (timer) clearInterval(timer);
    timer = setInterval(() => {
      setDuration((oldData) => {
        if (oldData >= maxRecordTime) {
          clearInterval(timer);
          stopRecord();
        } else {
          oldData += 1;
        }
        return oldData;
      });
    }, 1000);
  };

  return {
    isRecord,
    isPause,
    isSend,
    duration,
    // initRecord,
    startRecord,
    stopRecord,
    getRecord,
    handleSend,
    handleCancel,
    setIsSend,
  };
};

export const useChat = createModel(useChatResolve);
export const useRecord = createModel(useRecordStore);
