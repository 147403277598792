import { SxProps } from '@mui/material';

export const topContennt: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
export const sysName: SxProps = {
  display: 'inline-block',
  color: 'primary.contrastText',
  width: 224,
};
export const version: SxProps = {
  display: 'none',
  color: 'primary.contrastText',
};
export const company: SxProps = {
  color: 'primary.contrastText',
  paddingLeft: 1,
};

export const carousel: SxProps = {
  height: 30,
  width: 600,
};
export const carouselMsg: SxProps = {
  display: 'inline-block',
  color: 'primary.contrastText',
  minWidth: 600,
  lineHeight: '30px',
  cursor: 'pointer',
};
