import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './resources/en_US.json'; // 英语（美式）
import zhTW from './resources/zh_TW.json'; // 繁體中文(台灣)

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enUS,
    },
    zhTW: {
      translation: zhTW,
    },
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
