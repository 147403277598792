import React, { Suspense, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import './App.scss';
import routes from './routes';
import { SnackbarProvider } from 'notistack';
import GlobalMessage from './components/message/GlobalMessage';
import ChatWrapper from './pages/chat';
import arms from '@/utils/arms';

function App() {
  const element = useRoutes(routes);
  const lightTheme = createTheme({
    typography: {
      htmlFontSize: 18,
      // fontSize: 14
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#fafafa',
        paper: '#fff',
      },
    },
  });
  useEffect(() => {
    window.onerror = (msg, url, line, col, error) => {
      arms.error(error, {
        filename: url,
        lineno: line,
        colno: col,
      });
    };
  }, []);
  return (
    <div id='App'>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Suspense>{element}</Suspense>
          <GlobalMessage/>
          <ChatWrapper />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
