const storage = {
  // 设置缓存
  localSet<T>(name: string, data: T) {
    localStorage.removeItem(name);
    localStorage.setItem(name, JSON.stringify(data));
  },
  // 获取缓存
  localGet(name: string) {
    const data = localStorage.getItem(name);
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return data;
  },
  // 清除缓存
  localRemove(name: string) {
    localStorage.removeItem(name);
  },
  sessionSet<T>(name: string, data: T) {
    sessionStorage.removeItem(name);
    sessionStorage.setItem(name, JSON.stringify(data));
  },
  sessionGet(name: string) {
    const data = sessionStorage.getItem(name);
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return null;
  },
  sessionRemove(name: string) {
    sessionStorage.removeItem(name);
  },
};
export default storage;
