import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { uploadFile }     from '@/services/api/file';
import { useChat, useRecord } from '../../hooks';
import { UploadLayer }        from '../upload';
import { EmojiLayer }         from '../emoji';
import { ContentFields, SendMessageButtonProps } from '../../types';
import styles from './index.module.css';

import faceIcon  from '../../icon/face.png';
import imageIcon from '../../icon/image.png';
import audioIcon from '../../icon/audio.png';

// 工具栏，TODO这里后续需要迁移出去
const ToolsLayer = () => {
  const [isOpenEmoji, setIsOpenEmoji] = useState<boolean>(false);
  const { textareaRef, setTextareaValue, InsertMessage, setMsgType } = useChat();
  const { isRecord, isSend, setIsSend, startRecord, stopRecord, getRecord } = useRecord();

  useEffect(() => {
    if (isSend) handleSendAudio();
  }, [isSend]);

  // 图片上传
  const handleUploadImage = async (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('Buffer', files[i]);
      const res = await uploadFile(formData);
      if (res.Data && res.Data.Url) {
        const newMessage: ContentFields = {
          id: uuidv4(),
          message: res.Data.Url,
          status: 0,
          target: 0,
          time: parseInt((new Date().getTime() / 1000).toString()),
          type: 2,
          fileUrl: Math.ceil(files[i].size / 1000).toString(),
          fileName: uuidv4(),
        };
        InsertMessage(newMessage);
      }
    }
  };

  // 语音
  const handleRecord = () => {
    isRecord ? stopRecord() : startRecord();
  };

  const handleSendAudio = async () => {
    const data = getRecord();
    if (data.duration) {
      const formData = new FormData();
      const file = new File([data.file], (new Date().getTime()).toString(), { type: data.file.type, lastModified: Date.now() });
      formData.append('Buffer', file);
      const res = await uploadFile(formData);
      if (res.Data && res.Data.Url) {
        setIsSend(false);
        const newMessage: ContentFields = {
          id: uuidv4(),
          message: res.Data.Url,
          status: 0,
          target: 0,
          time: parseInt((new Date().getTime() / 1000).toString()),
          type: 3,
          fileUrl: data.duration.toString(),
          fileName: file.name,
        };
        InsertMessage(newMessage);
      }
    }
  };

  const handleOpenEmoji = () => {
    setIsOpenEmoji(!isOpenEmoji);
    textareaRef.current?.focus();
  };

  const handleEmojiCallback = (value: any) => {
    if (value.native) {
      const startPos = textareaRef.current?.selectionStart;
      const endPos = textareaRef.current?.selectionEnd;

      setTextareaValue((oldValue) => {
        let text = oldValue.slice(0, startPos) + value.native + oldValue.slice(startPos);
        if (startPos !== endPos) {
          text = oldValue.replace(oldValue.slice(startPos, endPos), value.native);
          setIsOpenEmoji(!isOpenEmoji);
        }

        setTimeout(() => {
          if (textareaRef.current) {
            const newPos = startPos ? startPos + value.native.length : text.length;
            textareaRef.current.selectionStart = newPos;
            textareaRef.current.selectionEnd   = newPos;
            textareaRef.current.focus();
            setIsOpenEmoji(!isOpenEmoji);
          }
        }, 10);
        return text;
      });
    }
  };

  return (
    <>
      <EmojiLayer open={isOpenEmoji} onCallback={handleEmojiCallback} onClose={() => setIsOpenEmoji(false)} />
      <dl className={styles.toolIconDl}>
        <li className={styles.toolIconItem} onClick={() => handleOpenEmoji()}>
          <img src={faceIcon} className={styles.toolImg} />
        </li>
        <li className={styles.toolIconItem} onClick={() => setMsgType(1)}>
          <UploadLayer multiple={false} onSelectFile={handleUploadImage} accept='image/*'>
            <img src={imageIcon} className={styles.toolImg} />
          </UploadLayer>
        </li>
        <li className={styles.toolIconItem} onClick={() => handleRecord()}>
          <img src={audioIcon} className={styles.toolImg} />
        </li>
      </dl>
    </>
  );
};

// 聊天输入框
export const TextareaLayer = () => {
  const { current, textareaRef, textareaValue, InsertMessage, setTextareaValue } = useChat((store) => [store.current, store.textareaRef, store.textareaValue]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKey);
    return () => document.removeEventListener('keydown', handleEnterKey);
  }, [current]);

  // 发送消息
  const handleSendMessage = () => {
    const message = (textareaRef?.current as HTMLTextAreaElement).value;
    if (!message) return;

    const messageItem: ContentFields = {
      id: (new Date().getTime()).toString(),
      message,
      status: 0,
      target: 0,
      time: parseInt((new Date().getTime() / 1000).toString()),
      type: 0,
    };

    InsertMessage(messageItem);
    setTextareaValue('');
  };

  // 键盘事件
  const handleEnterKey = (e: KeyboardEvent) => {
    const code = e.code;
    const ctrlKey = e.ctrlKey;
    const shiftKey = e.shiftKey;

    if (ctrlKey && code === 'Enter') {
      setTextareaValue((oldValue) => `${oldValue}\n`);
      return true;
    }

    if (!shiftKey && code === 'Enter') {
      e.preventDefault();
      setTimeout(() => {
        handleSendMessage();
      }, 100);
      return false;
    }
  };

  // 输入值改变
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target || {};
    setTextareaValue(target.value);
  };

  return (
    <>
      <textarea value={textareaValue} ref={textareaRef} className={styles.textareaCss} rows={5} onChange={handleOnChange} placeholder="輸入聊天信息，按Enter快速發送..." />
      <ButtonLayer onClick={handleSendMessage} />
    </>
  );
};

const ButtonLayer = (props: SendMessageButtonProps) => {
  return (
    <>
      <div className={styles.buttonLayerCss}>
        <div className="tips">按Ctrl+Enter换行，按Enter快速發送...</div>
        <button className={styles.buttonCss} onClick={props.onClick}>
          <span>發送</span>
        </button>
      </div>
    </>
  );
};

const InputWrapper = () => {
  return (
    <div className={styles.inputLayer}>
      <ToolsLayer />
      <TextareaLayer />
    </div>
  );
};

export default InputWrapper;
