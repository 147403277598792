function preFetchImg(src: string) {
  return new Promise<string>((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve(src);
    };
    img.src = src;
  });
}

export default preFetchImg;
