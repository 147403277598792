import React, { FC, useEffect } from 'react';
import { createSignalRContext } from 'react-signalr';
import { HubConnection }        from '@microsoft/signalr';
import { useSignalr }           from './hooks';
import { SignalRProps }         from './types';

const { useSignalREffect, Provider, connection } = createSignalRContext();
const maxReconnCount: number = 3; // 最大重试次数
let reconnectCount  : number = 0;

const SignalRWrapper: FC<SignalRProps> = (props) => {
  const { isRetry, setIsRetry, isSignalrError, setIsSignalrError, setMessage } = useSignalr();

  const url   = props.url;
  const token = props.token.replace('Bearer ', '');

  useEffect(() => {
    if (reconnectCount !== 0 && !isSignalrError) {
      reconnectCount = 0;
      setIsRetry(!isRetry);
    }

    return () => {
      connection?.stop();
    };
  }, [isSignalrError]);

  // 监听OnReceiveSugarChatMessageDto事件
  useSignalREffect('OnReceiveSugarChatMessageDto', (message) => {
    setMessage(message);
  }, []);
  useSignalREffect('OnReceiveRoutePlanningCompletionNoticeDto', (message) => {
    setMessage(message);
  }, []);

  // 连接错误
  const handleOnError = (_error?: Error | undefined) => {
    reconnectCount += 1;
    if (!isRetry) setIsRetry(true);
    if (reconnectCount > maxReconnCount && !isSignalrError) setIsSignalrError(true);
    return Promise.resolve();
  };

  // 重新连上服务
  const handleOnOpen = (_connection: HubConnection) => {
    setIsSignalrError(false);
    reconnectCount = 0;
  };

  function isConnectEnabled(): boolean {
    const isEnabled = reconnectCount < maxReconnCount && Boolean(url) && Boolean(token);
    return isEnabled;
  }

  return (
    <Provider
      connectEnabled={isConnectEnabled()}
      url={url}
      accessTokenFactory={() => token}
      onOpen={handleOnOpen}
      onError={handleOnError}
    >
    </Provider>
  );
};

export default SignalRWrapper;
