import { createModel } from 'hox';
import { useState } from 'react';

const usePreViewResolve = () => {
  const [preViewImage, setPreViewImage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  return {
    preViewImage,
    setPreViewImage,
    open,
    setOpen,
  };
};

export const usePreView = createModel(usePreViewResolve);
