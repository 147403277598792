import React, { useState }  from 'react';
import { createModel }      from 'hox';
import { Button }           from '@mui/material';
import { IOption, TableGridColumns } from '@/utils/types';
import {
  GridCallbackDetails,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { Filter }           from '@/utils/filter';
import { OnGetList }        from '@/services/api/driver';
import { HttpGetList }      from '@/services/lib/http-base';
import {
  FilterFields,
  FormStateType,
  FormTempResolve,
  TableDataFields,
  TableResolve,
  TableRowsMethodsType,
} from './types';

const drivingLicenseOptions: IOption[] = Filter('drivingLicenseOptions');

// Columns field actions 可以在 useContent裡面添加操作
const columns: TableGridColumns = [
  { field: 'DriverId', headerName: '司機Id', width: 70, key: true, hide: true },
  { field: 'DriverName', headerName: '姓名', minWidth: 150 },
  { field: 'DriverNumber', headerName: '司機編號', width: 140 },
  {
    field: 'DrivingLicenses',
    headerName: '駕照',
    width: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, TableDataFields>) => {
      if (params.row.DrivingLicenses) {
        return params.row.DrivingLicenses.map((item) => {
          return drivingLicenseOptions.find((opt) => opt.value === item)?.label || item;
        }).join(',');
      }
      return '';
    },
  },
  { field: 'VehicleName', headerName: '車輛', minWidth: 120 },
  { field: 'DriverSex', headerName: '性別', minWidth: 100, renderCell: (params: GridRenderCellParams) => Filter('sex', params.row[params.field]) },
  // { field: 'SsnNumber', headerName: 'SSN號碼', minWidth: 150 },
  // { field: 'PhoneNumber', headerName: '電話', width: 140 },
];

export const defaultFormTemp: FormTempResolve = {
  filter: null,
  loading: false,
  total: 0, // 總數量
  totalPage: 1, // 總頁數
  perPage: 10, // 每頁數量
  currentPage: 0, // 當前頁
  data: [],
};
const initParams: FilterFields = {
  PageIndex: defaultFormTemp.currentPage || 1,
  PageSize: defaultFormTemp.perPage || 10,
};

const useTableResolve = (): TableResolve<TableDataFields, FormStateType> => {
  const [formTemp, setFormTemp] = useState<FormTempResolve>(defaultFormTemp);
  const [currentRow, setCurrentRow] = useState<TableDataFields | null | undefined>(null);

  // 獲取列表數據
  const handleList = (fields?: FilterFields) => {
    const params = { ...initParams, ...fields };
    HttpGetList<FormTempResolve, TableDataFields, FilterFields>(OnGetList, setFormTemp, params);
  };

  // 方法集合
  const methods: TableRowsMethodsType<TableDataFields> = {
    // 打開基礎信息
    onSelect: (row: TableDataFields) => {
      setCurrentRow(row);
    },

    // 分頁相關方法
    onPageChange: (page: number, details: GridCallbackDetails<any>) => {
      handleList({ ...formTemp.filter, PageIndex: page + 1 });
    },

    // 頁數顯示數量改變
    onPageSizeChange: (pageSize: number, details: GridCallbackDetails<any>) => {
      handleList({ ...formTemp.filter, PageIndex: 1, PageSize: pageSize });
    },
  };

  // 為columns追加操作按鈕
  if (columns.findIndex(el => el.field === 'actions') === -1) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '操作',
      width: 120,
      getActions: (params) => [
        <Button key={params.id} size="small" onClick={() => methods.onSelect(params.row)} sx={{ minWidth: 'auto' }}>選擇</Button>,
      ],
    });
  }

  return {
    columns,
    formTemp,
    setFormTemp,
    currentRow,
    setCurrentRow,
    handleList,
    methods,
  };
};

export const useTable = createModel(useTableResolve);
