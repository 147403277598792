import React               from 'react';
import { ImChatDataProps } from './types';
import ImChatFloatLayer    from './components/float';
import ImChatBoxLayer      from './components/chatBox';
import PreviewWrapper      from './components/preview';
import MapWrapper          from './components/map/view';
import { useChat }         from './hooks';

export default function IMWrapper(props: ImChatDataProps) {
  const { current } = useChat((store) => [store.current]);

  return (
    <>
      {
        !current
          ? <ImChatFloatLayer />
          : <ImChatBoxLayer onSelectUser={props.onSelectUser}>{props.children}</ImChatBoxLayer>
      }
      <PreviewWrapper />
      <MapWrapper />
    </>
  );
};
