import { Dispatch, SetStateAction } from 'react';
import { DefaultPageParams, IPageListResolve } from '@/types/table.base.types';
import Message from '@/components/message/message';

// list接口公共方法
export const HttpGetList = <S, R, P extends DefaultPageParams>(api: Function, setFormTemp: Dispatch<SetStateAction<S>>, params: P) => {
  setFormTemp((oldData) => {
    return { ...oldData, loading: true };
  });
  api(params)
    .then(({ Data }: IPageListResolve<R>) => {
      setFormTemp((oldData) => {
        return { ...oldData, filter: params, data: Data.Items, total: Data.Count, currentPage: params.PageIndex, perPage: params.PageSize };
      });
    })
    .catch((error: Error) => Message.open((error as Error).message, { variant: 'warning' }))
    .finally(() => setFormTemp((oldData) => {
      return { ...oldData, loading: false };
    }));
};
