import Message from '@/components/message/message';
import { changePassword } from '@/services/api/user';
import { IChangePasswordParams } from '@/services/api/user.types';
import { validatePassword } from '@/utils/validate';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordTextField from '@/components/passwordTextField';

type IChangePassword = IChangePasswordParams;

export interface IChangePasswordModalRef {
  open: () => void;
}

const ChangePasswordModal: ForwardRefRenderFunction<IChangePasswordModalRef> = (props, ref) => {
  const { register, formState: { errors }, handleSubmit, reset } = useForm<IChangePassword>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));
  const handleClose = () => {
    setOpen(false);
  };
  const handleOK = () => {
    handleSubmit((data) => {
      setLoading(true);
      changePassword(data).then(() => {
        setOpen(false);
      }).catch((err) => {
        Message.error(err);
      }).finally(() => {
        setLoading(false);
      });
    })();
  };
  useEffect(() => {
    if (open) {
      reset({
        currentPassword: '',
        newPassword: '',
      });
    }
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogTitle>修改密碼</DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <PasswordTextField
          fullWidth
          size='small'
          label='當前密碼'
          margin='dense'
          type={'password'}
          {...register('currentPassword', { validate: validatePassword })}
          error={Boolean(errors.currentPassword)}
          helperText={
            errors.currentPassword ? errors.currentPassword.message : ' '
          }
        />
        <PasswordTextField
          fullWidth
          size='small'
          label='新密碼'
          margin='none'
          type={'password'}
          {...register('newPassword', { validate: validatePassword })}
          error={Boolean(errors.newPassword)}
          helperText={
            errors.newPassword ? errors.newPassword.message : ' '
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>取消</Button>
        <LoadingButton onClick={handleOK} loading={loading}>確認</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default forwardRef(ChangePasswordModal);
