import React          from 'react';
import MapLayer       from './index';
import { useMapView } from './hooks';
import styles         from './index.module.css';

const MapWrapper = () => {
  const { coords, openMap, setOpenMap } = useMapView();

  return (
    openMap
      ? <div className={styles.previewLayer}>
          <div className={styles.maskLayer} onClick={() => setOpenMap(false)}></div>
          <div className={styles.mapLayer}>
            <MapLayer resize={true} lat={coords.lat} lng={coords.lng} />
          </div>
        </div>
      : null
  );
};

export default MapWrapper;
