/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
// import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useChat } from '../../hooks';
import BadgeWrapper from '../badge';
import { ImUserList } from '../user';
import styles from './index.module.css';
import CloseIcon from '@mui/icons-material/Close';

// 固定浮动层
// let startPos = { x: 0, y: 0 };
const ImChatFloatLayer = () => {
  const { chatListData, openChatList, setOpenChatList } = useChat();

  const unreadArray = chatListData.map(item => item.unreadCount) || [];
  const unreadCount = unreadArray.length > 0 ? unreadArray.reduce((total, num) => total + num) : 0;

  // const handleStart: DraggableEventHandler = (_e, data) => {
  //   startPos = {
  //     x: data.x,
  //     y: data.y,
  //   };
  // };

  // const handleStop: DraggableEventHandler = (_e, data) => {
  //   if (data.x === startPos.x && data.y === startPos.y) {
  //     setOpen(!open);
  //   }
  // };

  const handleClose = () => {
    setOpenChatList(false);
  };

  return (
    <div className={`${styles.chatGroupWrapper} ${openChatList ? styles.open : ''}`} style={{ display: openChatList ? 'block' : 'none' }}>
      <div className={styles.header}>
        <div className={`${styles.left} drag-float`}>
          <div className={styles.title}>
            <h3>Tms Im</h3>
            <BadgeWrapper badgeContent={unreadCount} max={99} />
          </div>
        </div>
          <CloseIcon fontSize='medium' sx={{ color: '#666', cursor: 'pointer' }} onClick={handleClose}/>
      </div>
      <div className={`${styles.listLayer}`}>
        { useMemo(() => <ImUserList />, [chatListData]) }
      </div>
    </div>
  );
};

export default ImChatFloatLayer;
