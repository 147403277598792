import { api } from './api';
import { FilterEmptyData } from '@/utils/params';
import { IPageListResolve, IResolve } from '@/types/table.base.types';
import { IMessageFields, IChatItemFilter, IConversationsResolve, IFilterResolve, ISendMessageParams, GroupProfileFields } from '@/types/sugarChat.types';

// 获取全部用户会话
export const GetConversations = async (params: IFilterResolve | undefined): Promise<IPageListResolve<IConversationsResolve>> => {
  return await api.get('/SugarChat/conversations', { params: FilterEmptyData<IFilterResolve | undefined>(params) });
};

// 获取某个用户会话
export const GetChatItem = async (groupId: string, params: IChatItemFilter | undefined): Promise<IPageListResolve<IMessageFields>> => {
  return await api.get(`/SugarChat/${groupId}/messages`, { params: FilterEmptyData<IChatItemFilter | undefined>(params) });
};

// 获取全部Group组
export const GetAllGroup = async (): Promise<IPageListResolve<IConversationsResolve>> => {
  return await api.get('/SugarChat/getGroupByUserId');
};

// 发送聊天消息
export const SendMessage = async (params: ISendMessageParams): Promise<IResolve<any>> => {
  return await api.post('/SugarChat/sendMessage', { ...params });
};

// 消息设置为已读
export const ReadMessage = async (messageId: string): Promise<IResolve<any>> => {
  return await api.post('/SugarChat/readMessage', {
    messageId,
  });
};

// 查询当前登录人和司机是否存在群组不存在则创建一个
export const QueryDriverAndCurrentGroup = async (driverId: string): Promise<IResolve<GroupProfileFields>> => {
  return await api.post('/SugarChat/queryDriverAndCurrentGroup', { driverId });
};
