import { useState }          from 'react';
import { createModel }       from 'hox';
import { CustomRouteObject } from '@/routes/types';

const homeBreadcrumb: CustomRouteObject = { title: 'Home', path: '/' };
const useBreadcrumbResolve = () => {
  const [breadcrumb, setBreadcrumb] = useState<CustomRouteObject[]>([homeBreadcrumb]);

  const handleSetBreadcrumb = (data: CustomRouteObject[]) => {
    setBreadcrumb([homeBreadcrumb].concat(data.filter(item => item !== undefined)));
  };

  return {
    breadcrumb,
    handleSetBreadcrumb,
  };
};

export const useBreadcrumb = createModel(useBreadcrumbResolve);
