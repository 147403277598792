import { createModel } from 'hox';
import { useState }    from 'react';
import { IMapProps }   from './types';

const useMapViewResolve = () => {
  const [preViewMap, setPreViewMap] = useState<string>('');
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [coords, setCoords] = useState<IMapProps>({
    lat: 0,
    lng: 0,
  });

  return {
    preViewMap,
    setPreViewMap,
    openMap,
    setOpenMap,
    coords,
    setCoords,
  };
};

export const useMapView = createModel(useMapViewResolve);
