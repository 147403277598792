import { Rules } from './rules';

export const validateName = (value: string) => {
  if (Rules.empty(value)) {
    return 'Enter account';
  }
};
export const validatePassword = (value: string) => {
  if (Rules.empty(value)) {
    return 'Enter password';
  } else if (!Rules.password(value)) {
    return 'Password should be 8-16 characters length';
  }
};
export const validatePhoneNumber = (phoneNumber: string) => {
  const mobileRegForUSA = /^\d{10}$/g;
  if (Rules.empty(phoneNumber)) {
    return 'Enter phone number';
  } else if (!mobileRegForUSA.test(phoneNumber)) {
    return 'Invalid phone number';
  }
};
export const validateVerificationCode = (verificationCode: string) => {
  if (Rules.empty(verificationCode)) {
    return 'Enter verification code';
  } else if (verificationCode.length !== 6) {
    return 'Invalid verification code';
  }
};
export const validateEmail = (email: string) => {
  if (Rules.empty(email)) {
    return 'Enter email';
  } else if (!Rules.email(email)) {
    return 'Invalid email';
  }
};
export const validateUrl = (url: string) => {
  if (Rules.empty(url)) {
    return 'Enter url';
  } else if (!Rules.http(url)) {
    return 'Invalid url';
  }
};
