import { useEffect } from 'react';
import Message from './message';
import { useSnackbar } from 'notistack';

const GlobalMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    Message.open = (message, options?) => {
      enqueueSnackbar(message, options);
    };
  }, []);
  return null;
};
export default GlobalMessage;
