import React from 'react';
import H from '@here/maps-api-for-javascript';
import { userInfoStore } from '@/models';
import { IMapProps } from './types';

export default class Map extends React.Component<IMapProps, {}> {
  map: H.Map | null;
  ref: React.RefObject<HTMLDivElement>;
  svgMarkup: string;
  constructor(props: IMapProps) {
    super(props);
    this.ref = React.createRef();
    this.map = null;
    this.svgMarkup = '<svg width="24" height="24" ' +
      'xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
      'height="22" /><text x="12" y="18" font-size="12pt" ' +
      'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
      'fill="white">H</text></svg>';
  }

  componentDidMount() {
    if (!this.map && this.ref.current && userInfoStore.hereMapKey) {
      const platform = new H.service.Platform({
        apikey: userInfoStore.hereMapKey,
      });
      const coords = { lat: this.props.lat, lng: this.props.lng };
      const layers = platform.createDefaultLayers();

      const map = new H.Map(
        this.ref.current,
        layers.vector.normal.map,
        {
          pixelRatio: window.devicePixelRatio,
          center: { lat: 0, lng: 0 },
          zoom: 2,
        },
      );
      const icon = new H.map.Icon(this.svgMarkup);
      const marker = new H.map.Marker(coords, {
        icon,
        data: {
          isActive: true,
        },
      });

      map.addObject(marker);
      map.setCenter(coords);

      if (this.props.resize) {
        // H.ui.UI.createDefault(map, layers);
        const mapEvents = new H.mapevents.MapEvents(map);
        // eslint-disable-next-line no-unused-vars
        const behavior = new H.mapevents.Behavior(mapEvents);
        window.addEventListener('resize', () => map.getViewPort().resize());
      }

      this.map = map;
    }
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.removeObjects(this.map.getObjects());
    }
  }

  render() {
    return (
      <div ref={this.ref} style={{ width: '100%', height: '100%' }} />
    );
  }
}
