import React, { FC, ReactElement } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { userInfoStore } from '../models';

interface GuardProps extends RouteProps {
  valid?: boolean;
  element: React.ReactNode;
}

const RouterGuard: FC<GuardProps> = ({ valid = true, element }) => {
  if (valid) {
    if (userInfoStore.token) {
      return element as ReactElement;
    }
    return <Navigate to='/login' />;
  } else {
    return element as ReactElement;
  }
};

export default RouterGuard;
