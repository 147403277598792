import React, { Context, FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IDrawerProps, ISiderProps, ISiderContextProps } from './types';
import { Env } from '@/config/env';
import { observer } from 'mobx-react-lite';
import { userInfoStore } from '@/models';

export const SiderContext: Context<ISiderContextProps> = React.createContext({});

const Drawer = styled(MuiDrawer, {
  // shouldForwardProp: (prop) => prop !== "open",
})<IDrawerProps>(({ theme, open, drawerWidth }) => ({
  '&': {
    height: '100%',
  },
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth || 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const Version: FC = observer(() => {
  const { organization } = userInfoStore;
  if (!organization || organization.length <= 0) {
    return null;
  }
  return (
    <Typography color={'#999'} textAlign={'center'}>{organization[0].Name}  v{Env.release}</Typography>
  );
});

const Sider: FC<ISiderProps> = ({ children, onCollapse, defaultCollapsed = false, collapsible = false, ...props }) => {
  const [collapsed, setCollapsed] = useState(
    'collapsed' in props ? props.collapsed : defaultCollapsed,
  );

  useEffect(() => {
    if ('collapsed' in props) {
      setCollapsed(props.collapsed);
    }
  }, [props.collapsed]);
  const toggleDrawer = () => {
    const newStatus = !collapsed;
    if (!('collapsed' in props)) {
      setCollapsed(newStatus);
    }
    onCollapse?.(newStatus);
  };
  const contextValue = React.useMemo(
    () => ({
      siderCollapsed: collapsed,
    }),
    [collapsed],
  );
  return (
    <Drawer variant='permanent' open={!collapsed}>
      <SiderContext.Provider value={contextValue}>
        <div style={{
          height: '100%',
        }}>{children}</div>
      </SiderContext.Provider>
      {!collapsed && <Version/>}
      {collapsible && <Button sx={{ pl: 0, pr: 0, minWidth: 'auto' }} onClick={toggleDrawer} size='small' fullWidth>
        {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </Button>}
    </Drawer>
  );
};

export default Sider;
