import { useState }    from 'react';
import { createModel } from 'hox';
import { MessageType } from './types';

const useSignalrResolve = () => {
  const [isRetry, setIsRetry]               = useState<boolean>(false);
  const [isSignalrError, setIsSignalrError] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  return {
    isRetry,
    setIsRetry,
    isSignalrError,
    setIsSignalrError,
    message,
    setMessage,
  };
};

export const useSignalr = createModel(useSignalrResolve);
