import React, { FC } from 'react';
import dayjs from 'dayjs';
import { FormatMessageType, useChat } from '../../hooks';
import { ChatItemField, ChatItemProps } from '../../types';
import BadgeWrapper from '../badge';
import styles from './index.module.css';

// 聊天用户列表明细
export const ImUserItem: FC<ChatItemProps> = (props) => {
  const { current } = useChat(store => [store.current]);
  const { row } = props;
  const item = row.content[row.content.length - 1];

  const handleOnSelectRow = async (row: ChatItemField) => {
    row.onClick && row.onClick(row);
  };

  return (
    <li className={`${styles.chatItem} ${row?.id === current?.id ? styles.active : ''}`} onClick={() => handleOnSelectRow(row)}>
      <div className={styles.avatar}>
        { row.avatar ? <img className={styles.image} src={row.avatar} alt={row.username} /> : <span>{ row.username.slice(0, 1).toUpperCase() }</span> }
        <BadgeWrapper badgeContent={row.unreadCount} />
      </div>
      <div className={styles.body}>
        <div className={styles.top}>
          <div className={styles.username}>{row.username}</div>
          { item && item.time ? <div className={styles.time}>{dayjs(item.time * 1000).format('HH:mm')}</div> : null }
        </div>
        {
          item
            ? <div className={styles.content}>
                { item.type === 0 ? item.message : FormatMessageType(item) }
              </div>
            : null
        }
      </div>
    </li>
  );
};

// 用户列表
export const ImUserList = () => {
  const { chatListData } = useChat((store) => [store.chatListData]);
  if (chatListData.length <= 0) {
    return (
      <div className={styles.empty}>數據為空</div>
    );
  };
  const listItems = chatListData.map((item, index) => <ImUserItem key={item.id} index={index} row={item} />);
  return (
    <ul className={styles.chatList}>
      { listItems }
    </ul>
  );
};
