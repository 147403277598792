import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate }               from 'react-router-dom';
import { Divider, List, Collapse, ListItem }      from '@mui/material';
import ExpandLess                                 from '@mui/icons-material/ExpandLess';
import ExpandMore                                 from '@mui/icons-material/ExpandMore';
import routes                                     from '@/routes';
import { CustomRouteObject }                      from '@/routes/types';
import { SiderContext }                           from '@/components/layout/sider';
import { useBreadcrumb }                          from '../breadcrumb/hooks';
import ListItemWrapper from './menuItem';
import { checkTokenExp, userInfoStore } from '@/models';
import storage from '@/utils/storage';
import { MENU_AUTH_LIST } from '@/constants';
import Message from '../message/message';
import { GetMarkerRemarkTotalUnreadCount } from '@/services/api/address';

export default function MenuListWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSetBreadcrumb } = useBreadcrumb();
  const { siderCollapsed } = useContext(SiderContext);

  const [currentRoute, setCurrentRoute] = useState<CustomRouteObject | null>(null);
  const [currentCollapse, setCurrentCollapse] = useState<CustomRouteObject | null>(null);

  const [menuAuthList, setMenuAuthList] = useState<string[]>(storage.localGet(MENU_AUTH_LIST) || []);

  const routeArray = routes.filter(item => !item.hidden).map(item => item.children);
  const routeList  = routeArray.length > 0 ? routeArray[0] as CustomRouteObject[] : [];

  useEffect(() => {
    handleInitRoute();
  }, [location]);

  // 点击菜单
  const handleListItemClick = (item: CustomRouteObject) => {
    const breadcrumb: CustomRouteObject[] = [item];
    let url = item.path;

    if (routeList.find(el => el.path === item.path)) setCurrentCollapse(null);
    else if (currentCollapse) {
      breadcrumb.push(currentCollapse);
      url = `${currentCollapse.path}/${url}`;
    }
    if (url && item.meta?.validToken) {
      if (checkTokenExp(userInfoStore.token)) {
        navigate(url);
      } else {
        Message.open('登錄過期, 請重新登陸', { variant: 'warning' });
        navigate('/login');
      }
    } else if (url) {
      navigate(url);
    }
  };

  const handleCollapseChange = (item: CustomRouteObject, parent?: CustomRouteObject) => {
    if (siderCollapsed) {
      const url = `${parent?.path}/${item.path}`;
      url && navigate(url);
      return;
    }
    const value = currentCollapse?.path !== item.path ? item : null;
    setCurrentCollapse(value);
  };

  // 初始化路由
  const handleInitRoute = () => {
    const array = location.pathname.split('/').filter(item => item !== '');
    if (array.length > 0) {
      const route = handleFineRouteItem(routeList, `/${array[0]}`);
      if (array.length === 1) {
        handleSetBreadcrumb([route]);
        setCurrentRoute(route);
      } else {
        const collapse = handleFineRouteItem(route.children as CustomRouteObject[], array.slice(1).join('/'));
        handleSetBreadcrumb([route, collapse]);
        setCurrentCollapse(route);
        setCurrentRoute(collapse);
      }
    }
  };

  const handleFineRouteItem = (data: CustomRouteObject[], path: string) => {
    return data.find(item => item.path === path) as CustomRouteObject;
  };

  // 判斷是否有菜單導航權限
  const isHasMenuAuth = (item: CustomRouteObject) => {
    if (!item.meta?.name) return true;
    return menuAuthList.indexOf(item.meta?.name) !== -1;
  };
  // 獲取地址備註未讀總數
  const handleMarkerRemarUnreadCount = () => {
    GetMarkerRemarkTotalUnreadCount()
      .then(({ Data }) => userInfoStore.setMarkerRemarUnreadCount(Data.TotalUnreadCount || 0))
      .catch((error) => Message.open((error as Error).message, { variant: 'error' }));
  };

  useEffect(() => {
    window.refreshMenuAuthList = (menuList: string[]) => {
      setMenuAuthList(menuList);
      window.goDashboard();
      if (menuList.includes('ADDRESS')) {
        handleMarkerRemarUnreadCount();
      }
    };
  }, []);

  return (
    <List>
        {
          routeList.filter(item => !item.hidden).map((item, index) => {
            // 判斷是否有菜單導航權限
            if (!isHasMenuAuth(item)) {
              return null;
            }

            return (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                {
                  !item.children
                    ? <ListItemWrapper sx={{ backgroundColor: currentRoute?.path === item.path ? 'rgba(0, 0, 0, 0.04)' : 'none' }} collapsed={siderCollapsed} currentRoute={currentRoute} item={item} onClick={handleListItemClick} />
                    : <React.Fragment key={item.path}>
                        <ListItemWrapper sx={{ backgroundColor: currentCollapse?.path === item.path ? 'rgba(0, 0, 0, 0.04)' : 'none' }} collapsed={siderCollapsed} currentRoute={currentRoute} item={item} onClick={handleCollapseChange}>
                          { currentCollapse?.path === item.path ? <ExpandLess /> : <ExpandMore /> }
                        </ListItemWrapper>
                        {
                          !siderCollapsed
                            ? <Collapse in={currentCollapse?.path === item.path} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {
                                    item.children?.filter(item => !item.hidden).map((el) => {
                                      // 判斷是否有菜單導航權限
                                      if (!isHasMenuAuth(el)) {
                                        return null;
                                      }

                                      return (
                                        <ListItemWrapper key={el.path} sx={{ pl: 4 }} collapsed={siderCollapsed} currentRoute={currentRoute} item={el} onClick={handleListItemClick} />
                                      );
                                    })
                                  }
                                </List>
                              </Collapse>
                            : null
                        }
                    </React.Fragment>
                }
                { (index + 1) % 4 === 0 ? <Divider /> : null }
              </ListItem>
            );
          })
        }
    </List>
  );
}
