import React, { ChangeEvent, FC } from 'react';
import { UploadProps } from './types';
import styles from './index.module.css';

export const UploadLayer: FC<UploadProps> = (props) => {
  const handleFileOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const files = target?.files;
    if (files) {
      props.onSelectFile?.(files);
    }
  };

  return (
    <>
      <input className={styles.file} type="file" accept={props.accept} multiple={props.multiple} onChange={handleFileOnChange} />
      {props.children}
    </>
  );
};
