import React, { Suspense, useRef } from 'react';
import preFetchImg                 from './hooks';
import { IImgProps }               from './types';
import styles from './index.module.css';
import loadingImg from '../../icon/loading.png';

const LoadingImage = () => {
  return (
    <img className={styles.loading} src={loadingImg} />
  );
};

function LazyImg(props: IImgProps) {
  const { src, alt, onClick } = props;
  const load = useRef(false);

  const Img = (props: IImgProps) => {
    const { src, alt, onClick } = props;
    const wrapPromise = (promise: Promise<string>) => {
      let status = 'pending';
      let result = '';
      const suspender = promise.then(
        (r) => {
          status = 'success';
          result = r;
          load.current = true;
        },
        (e) => {
          status = 'error';
          result = e;
        },
      );
      return {
        read() {
          if (status === 'pending') {
            throw suspender;
          } else if (status === 'error') {
            throw result;
          } else if (status === 'success') {
            return result;
          }
        },
      };
    };
    if (!load.current) {
      wrapPromise(preFetchImg(src)).read();
    }
    return <img className={styles.lazyImg} src={src} alt={alt} onClick={onClick} />;
  };

  return (
    <div>
      <Suspense fallback={<LoadingImage />}>
        <Img src={src} alt={alt} onClick={onClick} />
      </Suspense>
    </div>
  );
}

export default LazyImg;
