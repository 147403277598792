import _ from 'lodash';
import React,  { useState, useEffect, useCallback } from 'react';
import dayjs                            from 'dayjs';
import { observer }                     from 'mobx-react-lite';
import DriverSelect                     from '@/components/driver';
import IMWrapper                        from '@/components/im';
import { ChatItemField, ContentFields } from '@/components/im/types';
import { TableDataFields }              from '@/pages/driver/types';
import { useChat }                      from '@/components/im/hooks';
import { converTime }                   from '@/utils/format';
import { GetChatItem, GetConversations, QueryDriverAndCurrentGroup, ReadMessage, SendMessage } from '@/services/api/sugarChat';
import { IPageListResolve } from '@/types/table.base.types';
import { userInfoStore }    from '@/models';
import Message              from '@/components/message/message';
import {
  IChatItemFilter,
  IConversationsResolve,
  IFilterResolve,
  IGroupResolve,
  ISendMessageParams,
  LastMessageFields,
  IMessageFields,
} from '@/types/sugarChat.types';
import { useSignalr } from '@/components/signalr/hooks';

const pageSize = 10;
const timerSecond = 1000 * 10;
let pageIndex  = 1;
let totalPage  = 1;
let chatTimer: ReturnType<typeof setInterval>;
let signalrTimer: ReturnType<typeof setTimeout>;

const ChatWrapper = observer(() => {
  const [open, setOpen] = useState(false);
  const { message, isSignalrError, setIsSignalrError } = useSignalr();
  const { current, setCurrent, setMyInfo, chatListData, setChatListData, setOldData, handleDataMake } = useChat((store) => [store.current, store.chatListData]);

  useEffect(() => {
    if (userInfoStore.userId) {
      handleAllChatList();
      const userInfo = {
        id: userInfoStore.userId,
        username: userInfoStore.userName,
        avatar: '',
        unreadCount: 0,
      };
      setMyInfo(userInfo);
    }
  }, [userInfoStore.userId]);

  // 这里是处理Signalr和轮询
  useEffect(() => {
    if (isSignalrError) {
      handleChatTimer();
      handleReConnectSignalr();
    } else clearInterval(chatTimer);

    return () => {
      clearInterval(chatTimer);
      clearTimeout(signalrTimer);
    };
  }, [isSignalrError, current, userInfoStore.userId]);

  // Signalr消息推送
  useEffect(() => {
    handleMergeMessage();
  }, [message]);

  // 处理Signalr新消息
  const handleMergeMessage = () => {
    if (message) {
      const index = chatListData.findIndex(item => item.id === message?.groupId);
      // 存在会话列表中
      if (index !== -1) {
        const copyData = _.cloneDeep(chatListData);
        const newMessage = handleGenerateMessage({
          Content: message.content,
          GroupId: message.groupId,
          Id: message.id,
          IsRevoked: message.isRevoked,
          SentBy: message.sentBy,
          SentTime: message.sentTime,
          SentUserName: message.sentUserName,
          Type: message.type,
          FileUrl: message.fileUrl as string,
        });

        // 当前会话窗口
        if (current && current.id === message?.groupId) {
          copyData[index].content = current.content.concat(newMessage);
          setCurrent(copyData[index]);

          // 把该消息设置为已读
          handleReadMessage(newMessage.id);
        } else {
          copyData[index].unreadCount += 1;
          copyData[index].content = [newMessage];
        }

        setChatListData(copyData);
        setOldData(copyData);
        return;
      }

      // 其他情况重新请求获取全部用户会话接口
      handleAllChatList();
    }
  };

  // 重新连接Signalr，60秒重试一次
  const handleReConnectSignalr = () => {
    if (signalrTimer) clearTimeout(signalrTimer);
    signalrTimer = setTimeout(() => {
      setIsSignalrError(false);
    }, 1000 * 60 * 1);
  };

  // 设置消息为已读
  const handleReadMessage = (messageId: string) => {
    if (messageId) ReadMessage(messageId);
  };

  // 获取全部用户会话
  const handleAllChatList = async () => {
    try {
      const userList: ChatItemField[] = [];
      const params: IFilterResolve = { PageIndex: 1, PageSize: 100 };
      const { Data }: IPageListResolve<IConversationsResolve> = await GetConversations(params);

      const data: IConversationsResolve[] = Data.Items || [];

      data.forEach((item: IConversationsResolve) => {
        const groupProfile: IGroupResolve = item.GroupProfile || {};
        const content: ContentFields[] = [];
        const lastMessage: LastMessageFields = item.LastMessage || {};

        if (lastMessage.Id) {
          const newMessage = handleGenerateMessage(lastMessage);
          content.push(newMessage);
        }

        const unreadCount = current && current.id === item.ConversationID ? 0 : item.UnreadCount;
        userList.push({
          id: item.ConversationID,
          username: groupProfile.Name || groupProfile.Description,
          avatar: groupProfile.AvatarUrl as string,
          unreadCount,
          content,
          onClick: handleOnSelectRow,
          onInput: handleOnInput,
          onScroll: handleOnScroll,
        });
      });

      setChatListData(userList);
      setOldData(userList);
    } catch (error) {
      Message.error(error as Error);
    }
  };

  // 选中某个聊天
  const handleOnSelectRow = async (row: ChatItemField) => {
    pageIndex = 1;
    const content = await handleGetChatItem(row);

    row.content = content.reverse();
    _.unionBy(row.content, 'id');
    row.unreadCount = 0;
    setCurrent(row);
  };

  // 获取用户聊天记录
  const handleGetChatItem = async (row: ChatItemField, newPageIndex?: number) => {
    const groupId = row.id as string;

    const params: IChatItemFilter = {
      PageIndex: newPageIndex || pageIndex,
      PageSize: pageSize,
    };

    const content: ContentFields[] = [];
    const { Data }: IPageListResolve<IMessageFields> = await GetChatItem(groupId, params);
    const data = Data.Items || [];
    totalPage = Data.TotalPages || 0;

    data.forEach(item => {
      const newMessage = handleGenerateMessage(item);
      content.push(newMessage);
    });

    return Promise.resolve(content);
  };

  // generate message
  const handleGenerateMessage = (item: IMessageFields) => {
    const dateTimeStr = converTime(new Date(item.SentTime).toJSON());
    const content: ContentFields = {
      id: item.Id,
      message: item.Type === 2 || item.Type === 3 ? item.FileUrl as string : item.Content, // Text=0, Location=1, Image=2, Voice=3, OrderType=4
      type: item.Type,
      target: item.SentBy === userInfoStore.userId ? 0 : 1,
      time: dayjs(dateTimeStr).unix(),
      userName: item.SentUserName,
      status: 0,
      fileUrl: '',
    };

    return content;
  };

  // 发送聊天
  const handleOnInput = async (id: string, item: ContentFields) => {
    const params: ISendMessageParams = {
      groupId: id,
      content: item.type === 0 ? item.message : item.fileUrl as string,
      messageType: item.type,
      fileUrl: item.message,
      fileName: item.fileName,
    };
    try {
      await SendMessage(params);
      return Promise.resolve({ status: 'ok' });
    } catch (error) {
      Message.error(error as Error);
      return Promise.resolve({ status: 'error' });
    }
  };

  // 滚动条，分页获取聊天记录
  const handleOnScroll = async (row: ChatItemField) => {
    if (pageIndex < totalPage) {
      pageIndex += 1;
      const content = await handleGetChatItem(row);
      row.content = content.reverse().concat(row.content);
      _.unionBy(row.content, 'id');
      row.unreadCount = 0;
      setCurrent(row);
    }
  };

  // 获取司机和小后台帐号的群组
  const getDriverGroupInfo = async (driverId: string) => {
    const res = await QueryDriverAndCurrentGroup(driverId);
    return res.Data;
  };

  // 選擇司機
  const handleDriverSelect = useCallback(async (row: TableDataFields | null | undefined) => {
    if (!row?.DriverId) return;
    const groupInfo = await getDriverGroupInfo(row.DriverId as string);
    if (!groupInfo?.Id) return;
    const groupId = groupInfo.Id;
    const groupName = groupInfo.Name;

    const index = chatListData.findIndex(item => item.id === groupId);
    if (index !== -1) {
      const copyData = _.cloneDeep(chatListData);
      const newData = copyData.splice(index, 1).concat(...copyData);
      handleDataMake(newData);
      return;
    }
    const data: ChatItemField = {
      id: groupId,
      username: groupName,
      avatar: '',
      unreadCount: 0,
      content: [],
      onClick: handleOnSelectRow,
      onInput: handleOnInput,
      onScroll: handleOnScroll,
    };

    const newData: ChatItemField[] = [data, ...chatListData];
    handleDataMake(newData);
    setOldData(newData);
  }, [chatListData]);

  // 选择用户
  const handleOnSelectUser = () => {
    setOpen(true);
  };

  // 定时器
  const handleChatTimer = () => {
    if (chatTimer) clearInterval(chatTimer);
    if (userInfoStore.userId) {
      chatTimer = setInterval(async () => {
        // 获取全部聊天用户数据
        handleAllChatList();
        if (current) {
          const item = chatListData.find(item => item.id === current.id);
          if (item?.id) {
            const content = await handleGetChatItem(current, 1);
            const newContent = _.xorBy(current.content, item.content.concat(current.content), content, 'id').filter(item => item.target === 1);
            current?.content.push(...newContent);
            setCurrent(_.cloneDeepWith(current));
          }
        }
      }, timerSecond);
    }
  };

  return (
    <>
      {
        userInfoStore.userId &&
          <>
            <IMWrapper onSelectUser={handleOnSelectUser} />
            <DriverSelect open={open} onClose={() => setOpen(false)} onSelect={handleDriverSelect} />
          </>
      }
    </>
  );
});

export default ChatWrapper;
