import React from 'react';
import { BadgeWrapperProps } from '../../types';
import styles from './index.module.css';

// 未读数量显示
const BadgeWrapper = (props: BadgeWrapperProps) => {
  const badgeContent = props.max && props.badgeContent > props.max ? props.max : props.badgeContent;
  if (!badgeContent) return null;
  return (
    <div className={styles.badgeLayer}>
      <span>{ badgeContent }</span>
    </div>
  );
};

export default BadgeWrapper;
