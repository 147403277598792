import { api } from './api';
import { FilterEmptyData } from '@/utils/params';
import { ICreateResolve, IUpdateResolve, IDeleteResolve, IPageListResolve, IResolve } from '@/types/table.base.types';
import {
  ITableListFields,
  IFilterFields,
  IFormFields,
  IMarkerPictureFields,
  IMarkerPictureIsTop,
  IMarkerCommentFields,
  IMarkerCommentIsTop,
  IUpdateRemarkMarker,
  IAddMarkerRemark,
  IUploadMarkerPictures,
  ITotalUnreadCount,
  IAddMarkerAvoidanceDriverParams,
  ILoadMarkerAvoidanceDriverParams,
  IAvoidanceData,
} from '@/types/address.types';

// 班次设置列表
export const OnGetList = async (params: IFilterFields | undefined): Promise<IPageListResolve<ITableListFields>> => {
  return await api.get('/customer/byAddress', { params: FilterEmptyData<IFilterFields | undefined>(params) });
};

// 同步信息
export const OnSyncAddressInfo = async (params: IFormFields): Promise<ICreateResolve> => {
  return await api.post('/customer/syncMakerPictureAndComment', { ...params });
};

// 獲取落货照
export const GetMarkerPictures = async (params: { MarkerId: string, MarkerImagesOverheadType: number }): Promise<IResolve<IMarkerPictureFields[]>> => {
  return await api.get('/customer/getMarkerPictures', { params });
};

// 置顶落货照
export const UpdateMarkerPicturesOverheadStatus = async (params: IMarkerPictureIsTop): Promise<IUpdateResolve> => {
  return await api.post('/customer/updateMarkerPicturesOverheadStatus', { ...params });
};

// 獲取司機備註
export const GetMarkerComments = async (params: { MarkerId: string }): Promise<IResolve<IMarkerCommentFields[]>> => {
  return await api.get('/customer/getMarkerComments', { params });
};

// 置顶司機備註
export const UpdateMarkerCommentsOverheadStatus = async (params: IMarkerCommentIsTop): Promise<IUpdateResolve> => {
  return await api.post('/customer/updateMarkerCommentOverheadStatus', { ...params });
};

// 刪除地址備註
export const DeleteMarkerRemark = async (markerRemarkId: string): Promise<IDeleteResolve> => {
  return await api.delete('/customer/DeleteMarkerRemark', {
    data: {
      markerRemarkId,
    },
  });
};

// 添加地址备注
export const AddMarkerRemark = async (params: IAddMarkerRemark): Promise<ICreateResolve> => {
  return await api.post('/customer/AddMarkerRemark', { ...params });
};

// 编辑地址备注
export const UpdateRemarkMarker = async (params: IUpdateRemarkMarker): Promise<IUpdateResolve> => {
  return await api.post('/customer/UpdateRemarkMarker', { ...params });
};

// 上傳Marker圖片
export const uploadMarkerPictures = async (params: IUploadMarkerPictures): Promise<IUpdateResolve> => {
  return await api.post('/customer/uploadMarkerPictures', { ...params });
};

// 刪除落貨照圖片
export const DeleteMarkerPictures = async (markerPicturesId: string): Promise<IUpdateResolve> => {
  return await api.delete('/customer/DeleteMarkerPictures', {
    data: { markerPicturesId },
  });
};

// 獲取地址備註未讀總署
export const GetMarkerRemarkTotalUnreadCount = async (): Promise<IResolve<ITotalUnreadCount>> => {
  return await api.get('/customer/LoadMarkerRemarkTotalUnreadCount');
};

// 修改地址备注是否已读状态
export const UpdateMarkerRemarkIsRead = async (params: { markerRemarkIds: string[] }): Promise<IResolve<IUpdateResolve>> => {
  return await api.post('/customer/UpdateMarkerRemarkIsRead', { ...params });
};

// 添加地址回避司机
export const LoadOrderMarkerAvoidanceDriver = async (params: ILoadMarkerAvoidanceDriverParams): Promise<IResolve<IAvoidanceData[]>> => {
  return await api.get('/customer/LoadMarkerAvoidanceDriver', { params });
};

// 添加地址回避司机
export const AddMarkerAvoidanceDriver = async (params: IAddMarkerAvoidanceDriverParams): Promise<ICreateResolve> => {
  return await api.post('/customer/AddOrUpdateMarkerAvoidanceDriver', { ...params });
};
