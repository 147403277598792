import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { FilterWrapper } from './fillter';
import { TableWrapper }  from './table';
import { DialogProps, IDriverModalProps, TableDataFields } from './types';
import { BootstrapDialogTitle, DialogWrapper, PaperComponent } from '../dialog';

// TableDialog
const TableDialog = (props: DialogProps) => {
  // 选择后关闭table组件
  const handleTableOnClose = (currentRow: TableDataFields | null | undefined) => {
    props.onSelect(currentRow);
    props.onClose();
  };

  return (
    <DialogWrapper
      open={props.open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="md"
      muiPaperRoot={{
        minWidth: '900px',
      }}
    >
      <BootstrapDialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={() => props.onClose()}>選擇司機</BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText component="div" sx={{ display: 'flex', flexDirection: 'column', height: '580px' }}>
          <FilterWrapper />
          <TableWrapper onSelect={handleTableOnClose} />
        </DialogContentText>
      </DialogContent>
    </DialogWrapper>
  );
};

// 司机组件
const DriverWrapper = (props: IDriverModalProps) => {
  return (
    <>
      { props.children }
      <TableDialog open={props.open} onSelect={props.onSelect} onClose={() => props.onClose()} />
    </>
  );
};

export default DriverWrapper;
