import React, { useState } from 'react';
import { observer }      from 'mobx-react-lite';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Badge, ListItemButton, ListItemIcon, ListItemText, MenuItem, MenuList, styled, SxProps, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { IListItemProps } from './types';
import { CustomRouteObject } from '@/routes/types';
import { userInfoStore }    from '@/models';

const listItemTextCss: SxProps = {
  pl: '10px',
  '& > span': { fontSize: '15px' },
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9',
  },
}));

function areEqual(prevProps: Readonly<IListItemProps>, nextProps: Readonly<IListItemProps>) {
  return prevProps.collapsed === nextProps.collapsed && prevProps.sx === nextProps.sx;
}

// listItem子组件
const ListItemWrapper = observer((props: IListItemProps) => {
  const { collapsed, currentRoute, item, onClick, children, sx } = props;
  const [open, setOpen] = useState(false);

  const handleItemClick = (item: CustomRouteObject, parent?: CustomRouteObject) => {
    setOpen(false);
    onClick && onClick(item, parent);
  };

  if (!collapsed) {
    return (
      <ListItemButton sx={{ ...sx }} selected={currentRoute?.path === item.path} onClick={() => handleItemClick(item)}>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          { item.meta?.icon ? item.meta?.icon : <InboxIcon /> }
        </ListItemIcon>
        {
          item.path === '/address' && userInfoStore.markerRemarUnreadCount > 0
            ? <Badge badgeContent={userInfoStore.markerRemarUnreadCount} color="secondary" variant="dot">
                <ListItemText sx={listItemTextCss} primary={item.title} />
              </Badge>
            : <ListItemText sx={listItemTextCss} primary={item.title} />
        }
        { children }
      </ListItemButton>
    );
  }

  return (
    <ListItemButton sx={{ ...sx }} selected={currentRoute?.path === item.path} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {
        <HtmlTooltip open={open} title={
          <MenuList sx={{ pt: 0, pb: 0 }}>
            {
              item.children
                ? item.children.filter(el => !el.hidden).map(el => <MenuItem key={el.path} sx={{ backgroundColor: currentRoute?.path === el.path ? 'rgba(0, 0, 0, 0.04)' : 'none' }} onClick={() => handleItemClick(el, item)}>{ el.title }</MenuItem>)
                : <MenuItem sx={{ backgroundColor: currentRoute?.path === item.path ? 'rgba(0, 0, 0, 0.04)' : 'none' }} onClick={() => handleItemClick(item)}>{ item.title }</MenuItem>
            }
          </MenuList>
          } sx={{ cursor: 'pointer' }} placement="right">
            <ListItemIcon sx={{ minWidth: '30px' }}>
              { item.meta?.icon ? item.meta?.icon : <InboxIcon /> }
            </ListItemIcon>
        </HtmlTooltip>
      }
      <ListItemText sx={listItemTextCss} primary={item.title} />
      { children }
    </ListItemButton>
  );
});

export default React.memo(ListItemWrapper, areEqual);
