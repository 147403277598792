import { SxProps } from '@mui/material';

export const operationBar: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: 2,
};

export const noBoxShadow: SxProps = {
  boxShadow: 'none',
};
