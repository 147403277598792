import React, { memo, useEffect } from 'react';
import Draggable from 'react-draggable';
import { ImChatDataProps } from '../../types';
import { useChat, useRecord } from '../../hooks';
import { ImUserList } from '../user';
import InputWrapper from '../input';
import MessageWrapper from '../message';
import styles from './index.module.css';

import closeIcon from '../../icon/close.png';
import recordIcon from '../../icon/record.png';

// 消息框
const ImChatMessageLayer = (props: ImChatDataProps) => {
  const { current, setCurrent, searchValue, setSearchValue } = useChat(store => [store.current, store.searchValue]);
  const { isRecord, isPause, duration, stopRecord, handleSend, handleCancel } = useRecord();

  useEffect(() => {
    const chatBox = document.querySelector('.chatBox');
    chatBox?.addEventListener('contextmenu', handleDisabledRightClick);

    return () => chatBox?.removeEventListener('contextmenu', handleDisabledRightClick);
  }, []);

  // 搜索列表联系人
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target || {};
    setSearchValue(target.value);
  };

  // 禁止右击
  const handleDisabledRightClick = (event: Event) => event.preventDefault();

  return (
    <Draggable handle=".drag-head">
      <div className={`${styles.messageWrapper} chatBox`}>
        {
          isRecord &&
            <div className={styles.maskLayer}>
              <div className={styles.maskBody}>
                <img className={styles.maskImage} src={recordIcon} />
                <div className={styles.recordTimeText}>{`${duration}s`}</div>
                {
                  !isPause
                    ? <button className={styles.button} onClick={() => stopRecord()}>停止</button>
                    : <div className={styles.btnGroup}>
                        <button className={`${styles.button} ${styles.send}`} onClick={() => handleSend()}>發送</button>
                        <button className={`${styles.button} ${styles.cancel}`} onClick={() => handleCancel()}>取消</button>
                      </div>
                }
              </div>
            </div>
        }

        <div className={styles.left}>
          <div className={styles.searchWrapper}>
            <input value={searchValue} className={styles.searchInput} type="text" onInput={handleSearchInput} placeholder="搜索會話列表聯係人" />
            <button style={{ marginLeft: '8px', padding: '3px 10px', border: '1px solid #ddd', cursor: 'pointer' }} onClick={props.onSelectUser}>+</button>
          </div>
          <ImUserList />
        </div>
        <div className={styles.main}>
          <div className={styles.contentLayer}>
            <div className={`${styles.head} drag-head`}>
              { current && <div className={styles.username}>{ current?.username }</div> }
              <div className={styles.close} onClick={() => setCurrent(null)}>
                <img style={{ width: '25px' }} src={closeIcon} />
              </div>
            </div>
            <MessageWrapper />
          </div>
          <InputWrapper />
        </div>
      </div>
    </Draggable>
  );
};

export default memo(ImChatMessageLayer);
