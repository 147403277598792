import { IResolve } from '@/types/communal.types';
import { api } from './api';
import {
  IChangePasswordParams,
  ILoginResolve,
  IPasswordLoginParams,
  ISendVerificationCodeParams,
  IUserInfoByEmail,
  IUserInfoResolve,
  IVerificationCodeLoginParams,
  IVerificationCodeResolve,
} from './user.types';

/**
 * 登录
 */
export const passwordlogin = async (params: IPasswordLoginParams): Promise<ILoginResolve> => {
  return await api.post('/identity/authenticate', params);
};
export const verificationCodeLogin = async (
  params: IVerificationCodeLoginParams,
): Promise<ILoginResolve> => {
  return await api.post('/identity/loginByVerificationCode', { ...params, from: 'web' });
};
export const getUserInfo = async (): Promise<IUserInfoResolve> => {
  return await api.get('/User/info');
};
export const getVerificationCode = async (
  params: ISendVerificationCodeParams,
): Promise<IVerificationCodeResolve> => {
  return await api.post('/identity/sendVerificationCode', params);
};
export const changePassword = async (
  params: IChangePasswordParams,
): Promise<IResolve<undefined>> => {
  return await api.post('/User/changePassword', params);
};
export const getHereMapKey = async (): Promise<IResolve<string>> => {
  return await api.get('/HereMap');
};

export const setUserOrderFields = (params: string[]): Promise<IResolve<string>> => {
  return api.post('/User/setField', { fields: params });
};

export const getUserOrderFields = async (): Promise<IResolve<string[]>> => {
  return await api.get('/User/getCurrentField');
};
// 根据邮箱找用户
export const getUserInfoByEmail = async (email: string): Promise<IResolve<IUserInfoByEmail>> => {
  return await api.get('/User/getUserInfoByEmail', {
    params: {
      Email: email,
    },
  });
};
