import React, { FC, useEffect, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { EmojiProps } from './types';
import './index.module.css';

export const EmojiLayer: FC<EmojiProps> = (props) => {
  const [oldOpen, setOldValue] = useState<boolean>(false);
  const { open } = props;

  useEffect(() => {
    setOldValue(open);
  }, [open]);

  const handleOnClickOutside = () => {
    if (oldOpen && props.onClose) props.onClose();
  };

  return (
    <>
      {
        open
          ? <Picker
              data={data}
              categories={['people']}
              navPosition='none'
              previewPosition='none'
              searchPosition='none'
              skinTonePosition='none'
              onEmojiSelect={props.onCallback}
              onClickOutside={() => handleOnClickOutside()}
            />
          : null
      }
    </>
  );
};
