import BrowserLogger from 'alife-logger';
import { Env } from '@/config/env';

const { arms, armsEnvironment, version } = Env;
// https://help.aliyun.com/document_detail/66404.html
const logger = (() => {
  if (arms.toLocaleLowerCase() === 'true') {
    try {
      return BrowserLogger.singleton({
        pid: 'hccokn11qx@9a18f14a0b1348e',
        uid: '',
        appType: 'web',
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        environment: armsEnvironment,
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        enableSPA: true,
        release: version,
      });
    } catch (e) {
      console.error('init logger fail', e);
    }
  } else {
    return {
      error() {},
    };
  }
})();
export default logger;
