import { IStartEndTimes } from '@/types/communal.types';
import moment from 'moment';

export const converTime = (dateTime: string) => {
  return dateTime.split('+')[0].replace('T', ' ');
};
export const formatTime = (dateTime: string, formatStr?: string) => {
  const dateTimeStr = converTime(dateTime);
  return moment(dateTimeStr).format(formatStr || 'YYYY-MM-DD HH:mm:ss');
};
export const utcTimeConverLocalTime = (dateTime: string, formatStr?: string) => {
  return moment(dateTime).format(formatStr || 'YYYY-MM-DD HH:mm:ss');
};

export const convertTime = (dateTime: IStartEndTimes) => {
  const { Start, End } = dateTime;
  if (Start && End) {
    const startTime = new Date(Start);
    const endTime = new Date(End);
    if (startTime.getDate() === endTime.getDate()) {
      return formatTime(Start, 'YYYY-MM-DD HH:mm') + '--' + formatTime(End, 'HH:mm');
    }
    return formatTime(Start, 'YYYY-MM-DD HH:mm') + '--' + formatTime(End, 'YYYY-MM-DD HH:mm');
  }
  return '';
};
