import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { carousel, carouselMsg } from './styles';
import { Carousel } from 'nuka-carousel';
import { Campaign } from '@mui/icons-material';
import { getRollNotice, OnFormUpdate } from '@/services/api/notice';
import Message from '@/components/message/message';
import { ITableListFields } from '@/types/notice.types';
import { useRequest } from 'ahooks';
import { Filter } from '@/utils/filter';
import { UPDATE_UNREAD_COUNT_EVENT } from '@/constants';
import PubSub from 'pubsub-js';
export const CarouselNotice: FC = () => {
  const [open, setOpen] = useState(false);
  const [noticeData, setNoticeData] = useState<ITableListFields[]>([]);
  const [currentNoticeIndex, setCurrentNoticeIndex] = useState(-1);
  const currentNotice = useMemo(() => {
    if (currentNoticeIndex < 0) {
      return null;
    }
    const data = currentNoticeIndex < noticeData.length ? noticeData[currentNoticeIndex] : null;
    if (data && !data.IsRead) {
      OnFormUpdate(data.Id);
    }
    return data;
  }, [currentNoticeIndex]);
  const showNotice = (id: string) => {
    const index = noticeData.findIndex((item) => item.Id === id);
    if (index >= 0) {
      setCurrentNoticeIndex(index);
      setOpen(true);
      getRollNoticeRequest.cancel();
    }
  };
  const closeNotice = () => {
    setOpen(false);
    setCurrentNoticeIndex(-1);
    getRollNoticeRequest.run();
    PubSub.publish(UPDATE_UNREAD_COUNT_EVENT);
  };
  const getRollNoticeRequest = useRequest(getRollNotice, {
    manual: true,
    pollingInterval: 1000 * 60 * 3, // 3分钟一次
    pollingErrorRetryCount: 3,
    onSuccess: (res) => {
      const { Data } = res;
      setNoticeData(Data);
    },
    onError(error) {
      Message.error((error as Error));
    },
  });
  useEffect(() => {
    getRollNoticeRequest.run();
  }, []);
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        {noticeData.length > 0 && <Campaign sx={{ color: '#fff', marginRight: 1 }} />}
        <Box sx={carousel}>
          <Carousel autoplay={true} autoplayInterval={3000} wrapMode='wrap' scrollDistance='slide'>
            {noticeData.map((item, index) => {
              return (
                <Typography
                  sx={carouselMsg}
                  variant='button'
                  whiteSpace={'nowrap'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  key={item.Id}
                  onClick={() => showNotice(item.Id)}
                >({index + 1})【{item.Title}】{item.Body}</Typography>
              );
            })}
          </Carousel>
        </Box>
      </Stack>
      <Dialog open={open} onClose={closeNotice} maxWidth={'sm'} fullWidth>
        <DialogTitle>{currentNotice?.Title}</DialogTitle>
        <DialogContent sx={{ height: '400px', overflow: 'auto' }}>
          <Stack direction={'row'} justifyContent={'space-around'} sx={{ mb: 1 }}>
            <Typography
              variant='inherit'
              fontSize={12}
              color={'#888'}
            >
              發佈人：{currentNotice?.SendBy}
            </Typography>
            <Typography
              variant='inherit'
              fontSize={12}
              color={'#888'}
            >
              發佈時間：{ Filter('formatDate', currentNotice?.CreatedDate, 'YYYY-MM-DD HH:mm:ss') }
            </Typography>
          </Stack>
          {currentNotice?.Body}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={currentNoticeIndex === 0}
            onClick={() => setCurrentNoticeIndex(currentNoticeIndex - 1)}
          >上一條</Button>
          <Button
            disabled={currentNoticeIndex === (noticeData.length - 1)}
            onClick={() => setCurrentNoticeIndex(currentNoticeIndex + 1)}
          >下一條</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
